import breadcrumbComp from "../../../common/breadcrumb-comp.vue";

import EncryptUtility from "../../../utility/js/encrypt-utility";
import AddFavourite from "../../../utility/js/addFav";
import BackToTop from "../../../common/back-to-top.vue";
import exportLimit from "../../../common/export-limit.vue";
import Utility from "../../../../shared/utility";
import reportService from "../../js/reports-service.js";
export default {
  name: "ARTStatusReport",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage:"",
      refresh:true,
      subPage:"",
      backSlash:"",
      showAdd:false,
      pageUrl: "",
      selectedStatus:"",
      statusList:[],
      selectedWorkcenter:"",
      workcenterList:[],
      reportData:[],
      showData:false,
      showRun:false,
      totalRecords:0,
      searchText:"",
      headerReport: [
        { text: "Request Number", value: "requestnumber", class: "primary customwhite--text" },
        { text: "RITM", value: "ritm", class: "primary customwhite--text" },
        { text: "Task Id", value: "taskid", class: "primary customwhite--text" },
        { text: "Serial No", value: "serialno", class: "primary customwhite--text" },
        { text: "Part No", value: "part_no", class: "primary customwhite--text",  },
        { text: "Task Identifier", value: "taskidentifier", class: "primary customwhite--text" },
        { text: "Assetaction", value: "assetaction", class: "primary customwhite--text" },
        { text: "Event Description", value: "eventdescription", class: "primary customwhite--text" },
        { text: "In Inventory", value: "currently_in_inventory", class: "primary customwhite--text" },
        { text: "Workcenter", value: "workcenter", class: "primary customwhite--text" },
        { text: "Warehouse", value: "warehouse", class: "primary customwhite--text" },
        { text: "Acquisition Method", align: "start", value: "acquisitionmethod", class: "primary customwhite--text" },
        { text: "Warranty",  value: "warranty", class: "primary customwhite--text" },  
        { text: "Record Created", value: "record_created", class: "primary customwhite--text" },
        { text: "Received", value: "received", class: "primary customwhite--text" },
        { text: "Received By", value: "received_by", class: "primary customwhite--text" },
        { text: "Completed On", value: "completed_on", class: "primary customwhite--text" },  
        { text: "Completed By", value: "completed_by", class: "primary customwhite--text" },
        
   
       
        
       
      ],
      excelName :'ART_Status_List' +new Date().toISOString().replace('T', '_').replace('Z', '')  .replace('.', '').replaceAll('-', '_') +'.xls',
      json_fields: {
        requestnumber:"requestnumber",
        ritm:"ritm",
        taskid:"taskid",
        serialno:"serialno",
        part_no:'part_no',
        taskidentifier:"taskidentifier",
        assetaction: 'assetaction',
        eventdescription:'eventdescription',
        currently_in_inventory:'currently_in_inventory',
        workcenter:"workcenter",
        warehouse:"warehouse",
        acquisitionmethod: 'acquisitionmethod',
        warranty: 'warranty',  
        received:'received',
        completed_on: 'completed_on',
        received_by:"received_by",
        completed_by: 'completed_by',
    
      },
}

  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    let statusData = await reportService.getARTStatusData("get",this.userId);
    this.statusList=statusData
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {},
  methods: {
    // set end Date
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    async onChangeStatus(status){
      this.showRun=false
      this.showData=false;
      this.selectedWorkcenter="";
      let workCenterData=await reportService.getWorkCenterList("get",this.userId,status)
      if(!workCenterData?.result){
      this.workcenterList=workCenterData}
      else{
        this.showRun=true;
        this.workcenterList=[]
      }
    },
     onChangeWorkcenter(){
      this.showData=false;
      this.showRun=true
    },
   async onClickRun(){
    this.reportData=[]
    this.totalRecords=this.reportData?.length
      let getTableData=await reportService.getARTStatusReport("get",this.userId,this.selectedStatus,this.selectedWorkcenter)
      if(getTableData?.message!="NA"){
        this.showData=true;
      getTableData?.forEach((element) => {
        element.record_created = Utility.convertESTToLocal(element?.record_created);
        element.received = Utility.convertESTToLocal(element?.received);
        if(element.warranty)
        element.warranty = Utility.convertESTToLocal(element?.warranty);
        this.reportData=getTableData
        this.totalRecords=this.reportData?.length
      });}
      else{
        this.showData=false;
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Record Found",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
      
 
    },
    resetFunction(){
      this.selectedWorkcenter="";
      this.selectedStatus="";
      this.showData=false;
      this.showRun=false;
      this.totalRecords=0
    }

  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
